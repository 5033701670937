import { Component, Input, OnInit } from '@angular/core';
import { ChatRoom, ChatService, NotificationService } from '@conpulse-web/core';

@Component({
  selector: 'conpulse-web-project-chat',
  templateUrl: './project-chat.component.html',
  styleUrls: ['./project-chat.component.scss'],
})
export class ProjectChatComponent implements OnInit {
  @Input() projectId: string;
  @Input() isSelection = false;
  @Input() isTeamMember = false;
  @Input() isSowSigned = false;
  @Input() step: string;
  @Input() isProjectPaused = false;
  @Input() isProjectCancelled = false;
  
  isLoading = false;
  chatRoom: ChatRoom;

  constructor(
    private readonly chatService: ChatService,
    private readonly notificationService: NotificationService
  ) {
  }

  ngOnInit(): void {
    this.getRoomDetails();
  }

  getRoomDetails() {
    this.isLoading = true;
    this.chatService.getChatRoomByProjectId(this.projectId, this.step).subscribe(
      (data) => {
        this.chatRoom = data?.data || {} as ChatRoom;
        this.isLoading = false;
      },
      (err) => {
        this.notificationService.openErrorSnackBar('Unable to get the chat details')
        this.isLoading = false;
      }
    );
  }
}
