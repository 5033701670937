import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DateFilterActionComponent } from './date-filter-action.component';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { ConpulseMaterialModule } from 'libs/conpulse/src/index';

@NgModule({
  declarations: [DateFilterActionComponent],
  imports: [
    CommonModule,
    ConpulseMaterialModule,
    FormsModule
  ],
  exports: [DateFilterActionComponent],
  entryComponents: [DateFilterActionComponent]
})
export class DateFilterActionModule { }
