import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  basicFormValues,
  List,
  UserService,
  ClientsListFilter,
  country,
  MarketExplorationCompanySizeFilterValues,
  ImproveoCompanyDiversityList,
  ImproveoCompanyCommunityImpactList,
  industryListBasedOnFirm,
  capabilityListBasedOnFirm,
  UserV2Service,
  NotificationService,
} from '@conpulse-web/core';
import { UtilityMethodsService } from '../../services';
import { cloneDeep, get, floor, isEmpty } from 'lodash-es';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'conpulse-web-demographics-filter',
  templateUrl: './demographics-filter.component.html',
  styleUrls: ['./demographics-filter.component.scss'],
})
export class DemographicsFilterComponent implements OnInit {
  MarketExplorationCompanySizeFilterValues = MarketExplorationCompanySizeFilterValues;
  ImproveoCompanyDiversity = ImproveoCompanyDiversityList;
  ImproveoCompanyCommunityImpactList = ImproveoCompanyCommunityImpactList;
  @ViewChild('form', { static: true }) form: NgForm;
  @Input() existingFilter = {} as ClientsListFilter;
  @Input() industryCapabilitySingleLevel: boolean = false;
  @Input() companySizeFilterEnabled: boolean = false;
  @Input() improveoDiversityFilterEnabled: boolean = false;
  @Input() improveoCommunityImpactFilterEnabled: boolean = false;
  @Input() ProviderFilterEnabled: boolean;
  @Input() clientFilterEnabled: boolean;
  @Input() clientList;
  @Output() back = new EventEmitter();
  basicFormValues = {} as basicFormValues;
  clientsListFilter = {} as ClientsListFilter;
  demographyList = {} as List;
  filteredCountryList: country[];
  filteredCity = [];
  filteredClient = [];
  filteredCompany = [];
  filteredProvider = [];
  selectedClient = [];
  selectedCities = [];
  selectedCompany = [];
  selectedProvider = [];
  industryChildList: industryListBasedOnFirm[];
  capabilityChildList: capabilityListBasedOnFirm[];
  searchTextSubscription: Subscription = null;
  cityPage = { page: 0, limit: 10, totalCount: 0 };
  clientPage =  { page: 0, limit: 10, totalCount: 0 };
  companyPage =  { page: 0, limit: 10, totalCount: 0 };
  providerPage =  { page: 0, limit: 10, totalCount: 0 };
  searchCity = '';
  searchClient = '';
  searchCompany = '';
  searchProvider = '';
  showMoreChips: boolean = false;
  consourceStatus = [];
  onBoardingStatus = [];
  marketplaceStatus = [];
  cgnStatus = [];
  stripeStatus = [];
  mspStatus = [];

  constructor(private userService: UserService, private utilityMethod: UtilityMethodsService, private userV2Service: UserV2Service,  private readonly notificationService: NotificationService,) {}

  ngOnInit() {
    this.basicFormValues.loading = true;
    this.loadDropdownValues();
    this.getClients();
    this.getCity();
    this.consourceStatus = ["ACTIVE", "INACTIVE", "PENDING"];
    this.onBoardingStatus = [
      { value:"PENDING", label: "PENDING" },
      { value:"ON_GOING", label: "ONGOING" },
      { value:"OFF_BOARDING", label: "OFFBOARDING" },
      { value: "DONE", label: "DONE" }];
    this.marketplaceStatus = ["ACTIVE", "INACTIVE"];
    this.cgnStatus = ["ACTIVE", "INACTIVE"];
    this.stripeStatus = [
      { value:"ACTIVE", label: "ACTIVE" },
      { value:"IN_ACTIVE", label: "INACTIVE" }];
    this.mspStatus = ["ACTIVE", "INACTIVE"];  

    this.searchTextSubscription = this.form.valueChanges
      .pipe(
        map((changes: any) => {
          return changes?.cityName || '';
        }),
        debounceTime(500),
        distinctUntilChanged((current: string, previous: string) => {
          return current?.toLowerCase() === previous?.toLowerCase();
        })
      )
      .subscribe((searchKey) => {
        this.getCity();
      });

      this.searchTextSubscription = this.form.valueChanges
      .pipe(
        map((changes) => changes?.searchClient || ''),
        debounceTime(500),
        distinctUntilChanged((current: string, previous: string) => {
          return current?.toLowerCase() === previous?.toLowerCase();
        })
      )
      .subscribe((searchKey) => {
        this.getClients();
      })

      this.searchTextSubscription = this.form.valueChanges
      .pipe(
        map((changes) => changes?.searchCompany || ''),
        debounceTime(500),
        distinctUntilChanged((current: string, previous: string) => {
          return current?.toLowerCase() === previous?.toLowerCase();
        })
      )
      .subscribe((searchKey) => {
        this.getCompanies();
      })

      this.searchTextSubscription = this.form.valueChanges
      .pipe(
        map((changes) => changes?.searchProvider || ''),
        debounceTime(500),
        distinctUntilChanged((current: string, previous: string) => {
          return current?.toLowerCase() === previous?.toLowerCase();
        })
      )
      .subscribe((searchKey) => {
        this.getProviders();
      })
  }

  clearSearch() {
    this.searchCity = '';
  }

  clearClientSearch() {
    this.searchClient = '';
    this.getClients();
  }

  clearCompanySearch() {
    this.searchCompany = '';
    this.getCompanies();
  }

  clearProviderSearch() {
    this.searchProvider = '';
    this.getProviders();
  }

  /**
   * Loads necessary filter dropdown values
   */
  loadDropdownValues() {
    this.userService.getCommonListForCompany().subscribe((dataOf) => {
      this.demographyList.regionList = dataOf.data.region;
      this.demographyList.industryList = dataOf.data.industry;
      this.demographyList.countryList = dataOf.data.country;
      this.demographyList.capabilityList = dataOf.data.capability;
      let data = {};
      if (!isEmpty(this.existingFilter)) {
        data = cloneDeep(this.existingFilter);
      }
      this.clientsListFilter.region = get(data, 'region', []);
      this.clientsListFilter.industry = get(data, 'industry', []);
      this.clientsListFilter.capability = get(data, 'capability', []);
      this.capabilityChildList = this.utilityMethod.filterIndustryCapabilities(this.clientsListFilter, 'capability', this.demographyList.capabilityList);
      this.industryChildList = this.utilityMethod.filterIndustryCapabilities(this.clientsListFilter, 'industry', this.demographyList.industryList);
      this.filterCountries();
      this.basicFormValues.loading = false;
    });
  }

  getCity(reloadLists = true) {
    if (reloadLists) {
      this.cityPage = { page: 0, limit: 10, totalCount: 0 };
    }
    const apiData = {
      ...this.cityPage,
      search: this.searchCity,
      country: this.clientsListFilter.country,
    };
    if(this.filteredCity?.length && this?.clientsListFilter?.city?.length) this.selectedCities = this.filteredCity.filter(city => this.clientsListFilter.city.includes(city._id));
    this.userV2Service.getCgnCities(apiData).subscribe(
      (response) => {
        this.demographyList.city = reloadLists ? response?.data?.cityList : [...this.demographyList.city, ...response?.data?.cityList];
        this.cityPage.page = response?.data?.paginationData.page;
        this.cityPage.totalCount = response?.data?.paginationData.total;
        this.cityPage.limit = response?.data?.paginationData.limit;
        this.filterCities();
      },
      (err) => {
        this.notificationService.openErrorSnackBar(err?.message || err);
      }
    );
  }

  getClients(reloadLists = true) {
    if (reloadLists) {
      this.resetClients();
    }
    const apiData = {
      ...this.clientPage,
      search: this.searchClient,
    };
    this.userService.getAllClients(apiData).subscribe(
      (response) => {
        if(reloadLists) this.filteredClient = [];
        if(response?.data?.clientList?.length) this.filteredClient = [...this.filteredClient,...response?.data?.clientList]
        if(this.selectedClient?.length && !this.searchClient) {
          const selectedUserIds = new Set(this.selectedClient.map((user) => user._id));
          this.clientsListFilter.client = Array.from(selectedUserIds);
          this.filteredClient = this.filteredClient.filter((user) => !selectedUserIds.has(user._id));
          this.filteredClient = [...this.filteredClient, ...this.selectedClient].sort((a, b) => a.clientName.localeCompare(b.clientName));
        }
        if (this.selectedClient.length && this.searchClient && response?.data?.users?.length) {
          this.clientPage.limit = response?.data?.paginationData?.limit;
        this.clientPage.page = response?.data?.paginationData?.page;
        this.clientPage.totalCount = response?.data?.paginationData?.total;
        }
      },
      (err) => {
        this.notificationService.openErrorSnackBar(err?.message || err);
      }
    );
  }

  getCompanies(reloadLists = true) {
    if (reloadLists) {
      this.resetCompany();
    }
    const apiData = {
      ...this.companyPage,
      search: this.searchCompany,
    };
    this.userService.getClientCompanies(apiData).subscribe(
      (response) => {
        if(reloadLists) this.filteredCompany = [];
        if(response?.data?.clientCompanyList?.length) this.filteredCompany = [...this.filteredCompany,...response?.data?.clientCompanyList]
        if(this.selectedCompany?.length && !this.searchCompany) {
          const selectedUserIds = new Set(this.selectedCompany.map((user) => user._id));
          this.clientsListFilter.company = Array.from(selectedUserIds);
          this.filteredCompany = this.filteredCompany.filter((user) => !selectedUserIds.has(user._id));
          this.filteredCompany = [...this.filteredCompany, ...this.selectedCompany].sort((a, b) => a.companyName.localeCompare(b.companyName));
        }
        if (this.selectedCompany.length && this.searchCompany && response?.data?.users?.length) {
          this.companyPage.limit = response?.data?.paginationData?.limit;
        this.companyPage.page = response?.data?.paginationData?.page;
        this.companyPage.totalCount = response?.data?.paginationData?.total;
        }
      },
      (err) => {
        this.notificationService.openErrorSnackBar(err?.message || err);
      }
    );
  }
  
  getProviders(reloadLists = true) {
    if (reloadLists) {
      this.resetProvider();
    }
    const apiData = {
      ...this.providerPage,
      search: this.searchProvider,
    };
    this.userService.getAllCompanies(apiData).subscribe(
      (response) => {
        if(reloadLists) this.filteredProvider = [];
        if(response?.data?.companyList?.length) this.filteredProvider = [...this.filteredProvider,...response?.data?.companyList]
        if(this.selectedProvider?.length && !this.searchProvider) {
          const selectedUserIds = new Set(this.selectedProvider.map((user) => user._id));
          this.clientsListFilter.provider = Array.from(selectedUserIds);
          this.filteredProvider = this.filteredProvider.filter((user) => !selectedUserIds.has(user._id));
          this.filteredProvider = [...this.filteredProvider, ...this.selectedProvider].sort((a, b) => a.firmName.localeCompare(b.firmName));
        }
        if (this.selectedProvider.length && this.searchProvider && response?.data?.users?.length) {
        this.providerPage.limit = response?.data?.paginationData?.limit;
        this.providerPage.page = response?.data?.paginationData?.page;
        this.providerPage.totalCount = response?.data?.paginationData?.total;
        }
      },
      (err) => {
        this.notificationService.openErrorSnackBar(err?.message || err);
      }
    );
  }
  
  setOpenedStatus(isOpen: boolean) {
    if (!isOpen && !this.clientsListFilter.client) {
      this.searchClient = '';
      this.resetClients();
    }
  }

  setCompanyOpenedStatus(isOpen: boolean) {
    if (!isOpen && !this.clientsListFilter.company) {
      this.searchCompany = '';
      this.resetCompany();
    }
  }

  setProviderOpenedStatus(isOpen: boolean) {
    if (!isOpen && !this.clientsListFilter.provider) {
      this.searchProvider = '';
      this.resetProvider();
    }
  }

  onUserSelected(selectedUser: MatSelectChange) {
    const users = this.filteredClient.filter((user) => selectedUser?.value?.includes(user._id));
    if (users?.length) {
      const ids = this.selectedClient.map((user) => user._id);
      users.forEach((user) => {
        if (!ids.includes(user._id)) {
          this.selectedClient.push(user);
        }
      });
    }
  }

onCompanySelected(selectedUser: MatSelectChange) {
  const users = this.filteredCompany.filter((user) => selectedUser?.value?.includes(user._id));
    if (users?.length) {
      const ids = this.selectedCompany.map((user) => user._id);
      users.forEach((user) => {
        if (!ids.includes(user._id)) {
          this.selectedCompany.push(user);
        }
      });
    }
}

onProviderSelected(selectedUser: MatSelectChange) {
  const users = this.filteredProvider.filter((user) => selectedUser?.value?.includes(user._id));
  if (users?.length) {
    const ids = this.selectedProvider.map((user) => user._id);
    users.forEach((user) => {
      if (!ids.includes(user._id)) {
        this.selectedProvider.push(user);
      }
    });
  }
}

  onScroll() {
    this.clientPage.page++;
    this.getClients(false);
  }

  onCompanyScroll() {
    this.companyPage.page++;
    this.getCompanies(false);
  }

  onProviderScroll() {
    this.providerPage.page++;
    this.getProviders(false);
  }

  onClosed() {
    this.searchClient = '';
    this.resetClients();
    this.getClients();
  }

  onCompanyClosed() {
    this.searchCompany = '';
    this.resetCompany();
    this.getCompanies();
  }

  onProviderClosed() {
    this.searchProvider = '';
    this.resetProvider();
    this.getProviders();
  }

  resetClients() {
    this.clientPage.page = 0;
    this.clientPage.limit = 10;
    this.clientPage.totalCount = 0;
  }

  resetCompany() {
    this.companyPage.page = 0;
    this.companyPage.limit = 10;
    this.companyPage.totalCount = 0;
  }

  resetProvider() {
    this.providerPage.page = 0;
    this.providerPage.limit = 10;
    this.providerPage.totalCount = 0;
  }

  removeSelected(id: string) {
    const cities = this.clientsListFilter.city.filter((cityId) => cityId !== id);
    this.clientsListFilter.city = cloneDeep(cities);
  }

  /**
   * Helper function for saving filter
   */
  onFormSubmit() {
    this.scrollToTop();
    this.back.emit(this.clientsListFilter);
  }

  disableFilter() {
    const filters = ['capability', 'companySize', 'country', 'improveoCommunityImpact', 'improveoDiversity', 'industry', 'region', 'other', 'city', 'consourceStatus', 'cgnStatus', 'marketplaceStatus', 'onBoardingStatus', 'company', 'stripeStatus', 'mspStatus', 'client', 'provider'];

    return filters.every((filter) => !this.clientsListFilter[filter]?.length && !this.existingFilter[filter]?.length);
  }

  /**
   * Navigates back to the list
   */
  navigateToList() {
    this.scrollToTop();
    this.clientsListFilter = cloneDeep(this.existingFilter);
    if (isEmpty(this.existingFilter) || this.searchCity) {
      this.searchCity = '';
      this.reset();
    }
    if (isEmpty(this.existingFilter) || this.searchClient) {
      this.searchClient = '';
      this.reset();
    }
    if (isEmpty(this.existingFilter) || this.searchCompany) {
      this.searchCompany = '';
      this.reset();
    }
    if (isEmpty(this.existingFilter) || this.searchProvider) {
      this.searchProvider = '';
      this.reset();
    }
    this.back.emit(null);
  }

  /**
   * Scrolls drawer to top
   */
  scrollToTop() {
    document.querySelector('#clientsListFilter')?.scrollTo(0, 0);
  }

  /**
   * Helper function to handle multi-select dropdowns
   */
  selectComplete({ type, items, id }): void {
    const isParentRemoved = id ? id : '';

    switch (type) {
      case 1:
        this.clientsListFilter.industry = items;
        this.industryChildList = this.utilityMethod.filterIndustryCapabilities(
          this.clientsListFilter,
          'industry',
          this.demographyList.industryList,
          isParentRemoved
        );
        break;
      case 2:
        this.clientsListFilter.region = items;
        this.filterCountries();
        break;
      case 3:
        this.clientsListFilter.country = items;
        this.getCity(true);
        break;
      case 4:
        this.clientsListFilter.capability = items;
        this.capabilityChildList = this.utilityMethod.filterIndustryCapabilities(
          this.clientsListFilter,
          'capability',
          this.demographyList.capabilityList,
          isParentRemoved
        );

        break;
      case 5:
        this.clientsListFilter.improveoDiversity = items;
        break;
      case 6:
        this.clientsListFilter.improveoCommunityImpact = items;
        break;
    }
  }

  onProviderScrolled() {
    if (this.cityPage.page < Math.floor(this.cityPage.totalCount / this.cityPage.limit)) {
      this.cityPage.page++;
      this.getCity(false);
    }
  }

  /**
   * Resets the filter
   */
  reset() {
    this.clientsListFilter.country = [];
    this.clientsListFilter.region = [];
    this.clientsListFilter.industry = [];
    this.clientsListFilter.capability = [];
    this.clientsListFilter.companySize = '';
    this.clientsListFilter.improveoCommunityImpact = [];
    this.clientsListFilter.improveoDiversity = [];
    this.clientsListFilter.city = [];
    this.clientsListFilter.stripeStatus = [];
    this.clientsListFilter.cgnStatus = [];
    this.clientsListFilter.consourceStatus = [];
    this.clientsListFilter.marketplaceStatus = [];
    this.clientsListFilter.onBoardingStatus = [];
    this.clientsListFilter.company = [];
    this.clientsListFilter.mspStatus = [];
    this.clientsListFilter.client = [];
    this.clientsListFilter.provider = [];
    this.industryChildList = [];
    this.capabilityChildList = [];
    this.searchCity = '';
    this.searchClient = '';
    this.searchCompany = '';
    this.searchProvider = '';
    this.selectedClient = [];
    this.selectedCompany = [];
    this.selectedProvider = [];
    this.getCity();
  }

  /**
   * Checks if save button is disabled or not
   * @returns Boolean value indicating whether the button is disabled
   */
  checkButtonDisabled() {
    return (
      isEmpty(this.clientsListFilter.country) &&
      isEmpty(this.clientsListFilter.region) &&
      isEmpty(this.clientsListFilter.industry) &&
      isEmpty(this.clientsListFilter.capability) &&
      isEmpty(this.clientsListFilter.companySize) &&
      isEmpty(this.clientsListFilter.improveoCommunityImpact) &&
      isEmpty(this.clientsListFilter.improveoDiversity) &&
      isEmpty(this.clientsListFilter.city) && 
      isEmpty(this.clientsListFilter.consourceStatus) &&
      isEmpty(this.clientsListFilter.cgnStatus) && 
      isEmpty(this.clientsListFilter.onBoardingStatus) &&
      isEmpty(this.clientsListFilter.marketplaceStatus) &&
      isEmpty(this.clientsListFilter.company) &&
      isEmpty(this.clientsListFilter.stripeStatus) &&
      isEmpty(this.clientsListFilter.mspStatus) &&
      isEmpty(this.clientsListFilter.client) &&
      isEmpty(this.clientsListFilter.provider)
    );
  }

  /**
   * Filter countries based on selected region
   */
  async filterCountries() {
    if (!isEmpty(this.clientsListFilter.region)) {
      this.filteredCountryList = [];
      this.clientsListFilter.region.map((regionId) => {
        (this.demographyList.countryList as country[]).map((country) => {
          if (regionId === country?.region?.['_id']) {
            this.filteredCountryList.push(country);
          }
        });
      });
    } else {
      this.filteredCountryList = this.demographyList.countryList as country[];
    }
  }

  /**
   * Filter countries based on selected region
   */
  async filterCities() {
    if (!isEmpty(this.clientsListFilter.country)) {
      const data = cloneDeep(this.filteredCity);
      this.filteredCity = [];
      const ides = [];
      this.clientsListFilter.country.map((id) => {
        [...this.demographyList.city, ...data].map((city) => {
          if (id === city?.country && !ides.includes(city._id)) {
            this.filteredCity.push(city);
            ides.push(city._id);
          }
        });
      });
      if(this.clientsListFilter?.city?.length) this.clientsListFilter.city = this.clientsListFilter.city.filter((id) => ides.includes(id));
    } else {
      if (!isEmpty(this.clientsListFilter.city)) {
        if(!this.demographyList?.city?.length && this.searchCity) {
          this.filteredCity = [];
          return;
        }
        const cities = this.demographyList?.city?.filter(city => !this.clientsListFilter.city.includes(city._id));
        this.filteredCity = [...this.selectedCities, ...cities].sort((a,b) => a.name.localeCompare(b.name))
      } else {
        this.filteredCity = cloneDeep(this?.demographyList?.city || []);
      }
    }
  }
}
