<mat-menu class="menu-container">
  <ng-template matMenuContent class="content">
    <ng-container *ngIf="!isLoading; else loader">
      <div class="message-container">
        <div class="d-flex align-items-center" *ngIf="!isLoading">
          <div class="profile-image d-flex align-items-center mr-3" *ngIf="userDetails?.picture">
            <img class="w-100 border-radius-full img-fit-cover" [src]="userDetails?.picture" />
          </div>
          <div class="profile-image d-flex align-items-center mr-3" *ngIf="!userDetails?.picture">
            <span class="alphabet">
                {{ userDetails?.name?.charAt(0)?.toUpperCase() }}
            </span>          
          </div>
          <div>
            <!-- TODO:- Move tooltip to ellipsisTooltip directive-->
            <div  matTooltipPosition="below" [matTooltip]="userDetails?.name" [matTooltipDisabled]="userDetails?.name?.length <= 26">
              <p class="user-name" ellipsisTooltip  [ellipsisLimit]="26">{{ userDetails?.name}}</p>
            </div>
            <div  matTooltipPosition="below" [matTooltip]="(CONSTANTS.CF_PROFILE_ROLES.includes(userDetails?.role) ? userDetails?.firmId?.firmName : userDetails?.companyId?.companyName)" [matTooltipDisabled]="(CONSTANTS.CF_PROFILE_ROLES.includes(userDetails?.role) ? userDetails?.firmId?.firmName?.length : userDetails?.companyId?.companyName?.length) <= 32">
              <p class="user-info" ellipsisTooltip [ellipsisLimit]="32">{{ CONSTANTS.CF_PROFILE_ROLES.includes(userDetails.role) ? userDetails?.firmId?.firmName : userDetails?.companyId?.companyName }}</p>
            </div>
            <div  matTooltipPosition="below" [matTooltip]="userDetails?.jobtitle" [matTooltipDisabled]="userDetails?.jobtitle?.length <= 32">
              <p class="user-info" ellipsisTooltip [ellipsisLimit]="32">{{ userDetails?.jobtitle }}</p>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <button type="submit" class="medium message-button" mat-flat-button color="secondary" (click)="handleClick()">
            <!-- <i class="material-icons pointer">send</i> -->
            {{ 'Message' }}
          </button>
        </div>
      </div>
    </ng-container>
    <ng-template #loader>
      <div class="message-container">
        <div class="d-flex align-items-center">
          <div class="profile-image d-flex align-items-center mr-2">
            <img class="w-100 border-radius-full img-fit-cover" [src]="defaultProfilePhoto" />
          </div>
          <div class="skeleton d-flex flex-1">
            <p class="bold skeleton-loader skeleton-title w-100 h-20"></p>
            <p class="bold skeleton-loader skeleton-title w-100 h-20"></p>
          </div>
        </div>
        <div class="mt-2 skeleton-loader skeleton-title w-30 h-30"></div>
      </div>
    </ng-template>
  </ng-template>
</mat-menu>
