<div class="d-flex align-items-center justify-content-between mb-4">
  <p mat-dialog-title>{{ title }}</p>
  <mat-icon mat-dialog-close class="close-icon">close</mat-icon>
</div>


<div mat-dialog-content cdkFocusInitial>
  <!-- <label class="form-label pt-2" *ngIf="type === 'warning'">{{ message }}</label> -->
  <div class="d-flex">
    <div class="flex-one">
      <label class="form-label">From :</label>
      <mat-form-field class="full-width modified-date-picker" floatLabel="never"
        [ngClass]="{'skeleton-loader skeleton-title w-100 h-auto' : basicFormValues?.loading}">
        <input matInput [matDatepicker]="startDate" placeholder="MM/DD/YYYY" name="startDate" [max]="eDate"
          #StartDate="ngModel" [(ngModel)]="sDate" (click)="toggleStartDatepicker()" [required]="!startDate.opened">
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker disabled="false" #startDate [opened]="startDatepickerOpened"></mat-datepicker>
        <mat-error *ngIf="StartDate.hasError('required')">Enter valid date</mat-error>
        <mat-error *ngIf="StartDate.invalid">Must be less than end date</mat-error>
      </mat-form-field>
    </div>
    <div class="flex-one ml-3">
      <label class="form-label">To :</label>
      <mat-form-field class="full-width modified-date-picker" floatLabel="never"
        [ngClass]="{'skeleton-loader skeleton-title w-100 h-auto' : basicFormValues?.loading}">
        <input matInput [matDatepicker]="endDate" placeholder="MM/DD/YYYY" name="endDate" [min]="sDate"
          [(ngModel)]="eDate" #EndDate="ngModel" (click)="toggleEndDatepicker()" [required]="!endDate.opened">
        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
        <mat-datepicker disabled="false" #endDate [opened]="endDatepickerOpened"></mat-datepicker>
        <mat-error *ngIf="EndDate.hasError('required')">Enter valid date</mat-error>
        <mat-error *ngIf="EndDate.invalid">Must be greater than start date</mat-error>
      </mat-form-field>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button type="button" mat-stroked-button mat-dialog-close color="accent" (click)="handleClick(false)">Cancel</button>
  <button type="submit" mat-flat-button mat-dialog-close
    [disabled]="!sDate || !eDate || EndDate.invalid || StartDate.invalid" color="primary" (click)="handleClick(true)">{{
    acceptanceText }}</button>
</div>