<div class="table-section table-with-white-bg" [class.billing-table-responsive]="tableValues?.type == 'invoiceBilling'"
  [class.entity-billing-table-responsive]="tableValues?.type == 'entityBilling'"
  [class.admin-entity-billing-table-responsive]="tableValues?.type == 'adminEntityBilling'"
  [class.overall-billing]="isOverallBilling" [class.table-container]="!isOverallBilling"
  [class.nodata-table]="isEmpty(tableValues.data) && !tableValues?.isLoading">
  <table mat-table *ngIf="!tableValues?.isLoading && !isEmpty(tableValues.data)" [dataSource]="tableValues.data"
    class="w-100">
    <!--Invoice Billing Table-->
    <ng-container matColumnDef="invoiceId">
      <th mat-header-cell *matHeaderCellDef [ngClass]="type">{{ 'INVOICE REF' | i18next }}</th>
      <td mat-cell *matCellDef="let element" class="">{{ element?.invoice }}</td>
    </ng-container>

    <ng-container matColumnDef="entityName">
      <th mat-header-cell *matHeaderCellDef [ngClass]="type">{{ 'ENTITY NAME' | i18next }}</th>
      <td mat-cell *matCellDef="let element" class="">{{ element?.legalEntity?.name || '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="firmName">
      <th mat-header-cell *matHeaderCellDef [ngClass]="type">{{ 'PROVIDER NAME' | i18next }}</th>
      <td mat-cell *matCellDef="let element" class="">{{ element?.consultingFirm?.firmName }}</td>
    </ng-container>

    <ng-container matColumnDef="clientName">
      <th mat-header-cell *matHeaderCellDef [ngClass]="type">{{ 'CLIENT NAME' | i18next }}</th>
      <td mat-cell *matCellDef="let element; let j = index" class="">
        <ng-container *ngIf="!element.user[0]?.picture; else profileImage">
          <span class="mr-1" [ngClass]="getClass(j)">
            {{ element.firstName?.charAt(0)?.toUpperCase() }}
          </span>
        </ng-container>
        <ng-template #profileImage>
          <span class="mr-1">
            <img class="profile-image" [src]="element.user[0]?.picture" />
          </span>
        </ng-template>
        {{ element?.client?.clientName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="firmContactName">
      <th mat-header-cell *matHeaderCellDef [ngClass]="type">{{ 'CONTACT NAME' | i18next }}</th>
      <td mat-cell *matCellDef="let element" class="">
        <div *ngIf="element.firmUser[0]; else noContact" class="d-flex align-items-center">
          <span *ngIf="!element.firmUser[0]?.picture; else profileImage">
            <img class="profile-image mr-1" [src]="defaultProfilePhoto" />
          </span>
          <ng-template #profileImage>
            <span class="mr-1">
              <img class="profile-image" [src]="element.firmUser[0]?.picture" />
            </span>
          </ng-template>
          <span>
            {{ (element?.firmUser)[0]?.name }}
          </span>
        </div>
      </td>
      <ng-template #noContact>
        {{ '-' }}
      </ng-template>
    </ng-container>

    <ng-container matColumnDef="companyName">
      <th mat-header-cell *matHeaderCellDef [ngClass]="type">{{ 'COMPANY NAME' | i18next }}</th>
      <td mat-cell *matCellDef="let element; let j = index" class="element-name">
        <div class="d-flex align-items-center">
          <ng-container *ngIf="!element?.client?.companyPicture; else profileImage">
            <span class="mr-1" [ngClass]="getClass(j)">
              {{ element?.client?.companyName?.charAt(0)?.toUpperCase() }}
            </span>
          </ng-container>
          <ng-template #profileImage>
            <span>
              <img class="profile-image mr-1" [src]="element?.client?.companyPicture" />
            </span>
          </ng-template>
          <p>
            {{ element?.client?.companyName }}
          </p>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="issuedOn">
      <th mat-header-cell *matHeaderCellDef [ngClass]="type">{{ 'ISSUED ON' | i18next }}</th>
      <td mat-cell *matCellDef="let element" class="">{{ element?.issuedOnDate | date }}</td>
    </ng-container>

    <ng-container matColumnDef="amountDue">
      <th mat-header-cell *matHeaderCellDef [ngClass]="type">{{ 'TOTAL (incl VAT)' | i18next }}</th>
      <td mat-cell *matCellDef="let element" class="">
        {{ element?.amountDue ? element?.currency?.symbol : ''
        }}{{ element?.amountDue ? ((element?.amountDue | amountConversion: element?.currency?.minorUnits) | delimit) :
        '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="dueDate">
      <th mat-header-cell *matHeaderCellDef [ngClass]="type">{{ 'DUE DATE' | i18next }}</th>
      <td mat-cell *matCellDef="let element" class="">{{ (element?.dueDate | date) || '-' }}</td>
    </ng-container>


    <ng-container matColumnDef="invoiceType">
      <th mat-header-cell *matHeaderCellDef [ngClass]="type">{{ 'TYPE' | i18next }}</th>
      <td mat-cell *matCellDef="let element" class="">
        <ng-container [ngSwitch]="element?.type">
          <ng-container *ngSwitchCase="'marketplace'"> {{'Marketplace' | i18next}}</ng-container>
          <ng-container *ngSwitchCase="'managed-service'"> {{'Managed Service' | i18next}}</ng-container>
          <ng-container *ngSwitchDefault> {{'Platform' | i18next}}</ng-container>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="taxPerc">
      <th mat-header-cell *matHeaderCellDef [ngClass]="type">{{ 'Tax Percent' | i18next }}</th>
      <td mat-cell *matCellDef="let element" class="">{{ element?.taxPerc ? element?.taxPerc + '%' : '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="emptyDiv">
      <th mat-header-cell *matHeaderCellDef [ngClass]="type"></th>
      <td mat-cell *matCellDef="let element" class=""></td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef [ngClass]="type">{{ 'AMOUNT' | i18next }}</th>
      <td mat-cell *matCellDef="let element" class="">
        {{ element?.currency?.symbol }}{{ (element?.amount | amountConversion: element?.currency?.minorUnits) | delimit
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="clientAmount">
      <th mat-header-cell *matHeaderCellDef [ngClass]="type">{{ 'AMOUNT' | i18next }}</th>
      <td mat-cell *matCellDef="let element" class="">
        {{ element?.currency?.symbol }}{{ (element?.amount | amountConversion: element?.currency?.minorUnits) | delimit
        }}
      </td>
    </ng-container>
    <!-- entity amount -->
    <ng-container matColumnDef="entityAmount">
      <th mat-header-cell *matHeaderCellDef [ngClass]="type">{{ 'AMOUNT' | i18next }}</th>
      <td mat-cell *matCellDef="let element" class="">
        {{ element.currency?.symbol }}{{ (element?.amount | amountConversion: element?.currency?.minorUnits) | delimit
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef [ngClass]="type">{{ 'STATUS' | i18next }}</th>
      <td mat-cell *matCellDef="let element" class="">
        <span class="w-100px bold tag" [ngClass]="'tag-' + element?.status">{{ element?.status | titlecase | i18next
          }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="paymentInfo">
      <th mat-header-cell *matHeaderCellDef [ngClass]="type">{{ 'PAYMENT INFO' | i18next }}</th>
      <td mat-cell *matCellDef="let element" class="">{{ element?.paymentInfo || '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="paymentCompletedDate">
      <th mat-header-cell *matHeaderCellDef [ngClass]="type">{{ 'PAID ON' | i18next }}</th>
      <td mat-cell *matCellDef="let element" class="">{{ (element?.paymentCompletedDate | date) || '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="receiptLink">
      <th mat-header-cell *matHeaderCellDef [ngClass]="type">{{ 'VIEW RECEIPT' | i18next }}</th>
      <td mat-cell *matCellDef="let element">
        <div class="tootip-drop cursor" *ngIf="element?.receiptPdf">
          <a (click)="viewPdf(element?.receiptPdf)">
            <mat-icon class="mat-20 mr-3 ml-0">visibility</mat-icon>
          </a>
          <div class="tool-tip">
            <p>View Receipt</p>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="userEdit">
      <th mat-header-cell *matHeaderCellDef class="p-0"></th>
      <td mat-cell *matCellDef="let element" class="p-0">
        <div class="line-height d-flex" *ngIf="tableValues.isAdmin && element?.status === 'draft'">
          <mat-icon class="mat-20 delete mr-3" (click)="editElement(element)">edit</mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="text-center" [ngClass]="type">ACTION</th>
      <td mat-cell *matCellDef="let element">
        <div class="d-flex align-items-center justify-content-center">
          <div *ngIf="[invoiceStatus.OPEN, invoiceStatus.DRAFT].includes(element?.status); else viewReceipt">
            <mat-icon class="p-1 pointer border-radius-full mat-20 more-actions"
              [matMenuTriggerFor]="statusMenu">more_vert </mat-icon>
            <mat-menu #statusMenu="matMenu">
              <button *ngIf="checkButtonVisibility(invoiceStatus.VOID, element.status)" mat-menu-item
                (click)="editElement(element, invoiceStatus.VOID)">
                <mat-icon>block</mat-icon> {{ 'Void' | i18next }}
              </button>
              <button *ngIf="checkButtonVisibility(invoiceStatus.UNCOLLECTIBLE, element.status)" mat-menu-item
                (click)="editElement(element, invoiceStatus.UNCOLLECTIBLE)">
                <mat-icon>receipt_long</mat-icon> {{ 'Uncollectible' | i18next }}
              </button>
              <button *ngIf="checkButtonVisibility(invoiceStatus.PAID_OUT_OF_BAND, element.status)" mat-menu-item
                (click)="editElement(element, invoiceStatus.PAID)">
                <mat-icon>monetization_on</mat-icon> {{ 'Paid out of band' | i18next }}
              </button>
              <button *ngIf="tableValues.isAdmin && element?.status === 'draft'" mat-menu-item
                (click)="editElement(element)">
                <mat-icon>edit</mat-icon>{{ 'Edit' | i18next }}
              </button>
              <button *ngIf="
                  tableValues.isAdmin
                    ? [invoiceStatus.PAID, invoiceStatus.UNCOLLECTIBLE, invoiceStatus.VOID, invoiceStatus.OPEN].includes(element.status)
                    : element?.invoiceLink
                " (click)="openUrl(element?.invoiceLink)" mat-menu-item>
                <mat-icon>receipt</mat-icon> {{ element.status !== invoiceStatus.PAID ? ('View Invoice' | i18next) :
                ('View Receipt' | i18next) }}
              </button>
              <button *ngIf="checkButtonVisibility(invoiceStatus.OPEN, element.status)" mat-menu-item
                (click)="editElement(element, invoiceStatus.OPEN)">
                <mat-icon>fact_check</mat-icon> {{ 'Finalize' | i18next }}
              </button>
            </mat-menu>
          </div>
          <ng-template #viewReceipt>
            <div class="decoration-none">
              <a href="{{ element?.invoiceLink }}" target="_blank"
                matTooltip="{{ element.status !== invoiceStatus.PAID ? ('View Invoice' | i18next) : ('View Receipt' | i18next) }}"
                matTooltipPosition="above">
                <mat-icon class="p-1 pointer border-radius-full mat-20 view-action">visibility</mat-icon>
              </a>
            </div>
          </ng-template>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="viewInvoice">
      <th mat-header-cell *matHeaderCellDef class="text-center" [ngClass]="type">{{ 'ACTION' | i18next }}</th>
      <td mat-cell *matCellDef="let element" class="text-center">
        <div class="more-actions" *ngIf="
            tableValues.isAdmin
              ? [invoiceStatus.PAID, invoiceStatus.UNCOLLECTIBLE, invoiceStatus.VOID, invoiceStatus.OPEN].includes(element.status)
              : element?.invoiceLink
          ">
          <a class="view-actions" href="{{ element?.invoiceLink }}" target="_blank"
            matTooltip="{{ element.status !== invoiceStatus.PAID ? ('View Invoice' | i18next) : ('View Receipt' | i18next) }}"
            matTooltipPosition="above">
            <mat-icon class="p-1 pointer border-radius-full mat-20 view-actions">visibility</mat-icon>
          </a>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="tableValues.displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: tableValues.displayedColumns"></tr>
  </table>
  <div class="loader" *ngIf="tableValues?.isLoading">
    <div *ngFor="let i of [1, 2, 3, 4, 5]; let j = index; let first = first" class="skeleton-table">
      <span class="">&nbsp;</span>
      <span class="">&nbsp;</span>
      <span class="skeleton-loader skeleton-title w-25 mr-2">&nbsp;</span>
      <span class="skeleton-loader skeleton-title w-20">&nbsp;</span>
      <span class="skeleton-loader skeleton-title w-20">&nbsp;</span>
      <span class="skeleton-loader skeleton-title w-20">&nbsp;</span>
      <span class="skeleton-loader skeleton-title w-12">&nbsp;</span>
    </div>
  </div>
  <div class="bg-white p-12 text-center border-radius-6 h-100"
    *ngIf="isEmpty(tableValues.data) && !tableValues?.isLoading">
    <div class="d-flex align-items-center justify-content-center flex-column h-100">
      <img class="vector-image" [src]="notFoundImage" alt="no data image" />
      <p class="nodata-title font-Jakarta m-t-20">{{ 'No data found' | i18next }}</p>
      <ng-container *ngIf="errorMessage; else defaultMesssage">
        <p class="nodata-summary mw-400 m-t-12">{{ errorMessage }}</p>
      </ng-container>
      <ng-template #defaultMessage>
        <p class="nodata-summary mw-400 m-t-12">
          {{ "Sorry, we couldn't find any results for this search, please try different keyword" | i18next }}
        </p>
      </ng-template>
    </div>
  </div>
</div>

<div class="m-t-12" *ngIf="isPagination && !tableValues?.isLoading && !isEmpty(tableValues.data)">
  <!-- <p class="mr-2 bold">{{ 'Records per page' | i18next }}</p>
    <p class="mr-4">10</p>
    <p class="mr-4">{{ tableValues.page + 1 }} {{'of' | i18next}} {{ tableValues.endPage }}</p>
    <p class="mr-4" (click)="changePage(1)"><i class="material-icons">first_page</i></p>
    <p class="mr-4" (click)="changePage('-')"><i class="material-icons">keyboard_arrow_left</i></p>
    <p class="mr-4" (click)="changePage('+')"><i class="material-icons">keyboard_arrow_right</i></p>
    <p (click)="changePage(tableValues.endPage)"><i class="material-icons">last_page</i></p> -->
  <mat-paginator [pageSizeOptions]="[10, 25, 50]" [length]="tableValues.totalCount" [pageSize]="tableValues.limit"
    showFirstLastButtons [pageIndex]="tableValues.page" (page)="handlePageEvent($event)" aria-label="Select page">
  </mat-paginator>
</div>