import { Inject, Injectable, OnDestroy } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { PermissionService } from '../services/permission.service';
import { map, takeUntil } from 'rxjs/operators';
import { UserService } from '../apis';
import { CONSTANTS } from '../constants';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  constructor(
    private permissionService: PermissionService,
    private router: Router,
    private userService: UserService,
    @Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService,
    @Inject(DOCUMENT) private document: any
  ) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const module: string = next.data.module;
    const accessAllowed: string[] = next.data?.access ?? ['view', 'viewAll'];
    this.changeLanguage();
    return this.permissionService.getPermissionsList().pipe(
      map((response) => {
        if (response?.[module]?.length && accessAllowed.some((access) => response[module].includes(access))) {
          return true;
        }
        CONSTANTS.CLIENT_USER_ROLES.includes(this.userService.currentUserInformation?.role)
          ? this.router.navigate(['/dashboard'])
          : this.router.navigate(['/invitation-dashboard']);
        return false;
      }),
      takeUntil(this.unsubscribe$)
    );
  }

  //For changing I18next language based on user's language
  changeLanguage = () => {
    this.userService.getUser(this.userService.currentUserInformation?._id).subscribe((data) => {
      const lang = data.data?.language?.isoCode.toUpperCase();
      if (lang?.toLowerCase() !== this.i18NextService.language?.toLowerCase()) {
        this.i18NextService.changeLanguage(lang);
        this.document.location.reload();
      }
    });
  };

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
