<form role="form" #form="ngForm">
  <div class="drawer-header position-relative d-flex align-items-center justify-content-between p-4">
    <h4 class="drawer-title">{{ 'Filter' | i18next }}</h4>
    <div class="d-flex justify-content-end align-items-center">
      <button mat-stroked-button (click)="reset()" color="secondary" class="medium clear-filter-btn" [class.hide]="checkButtonDisabled()">
        {{ 'Clear filter' | i18next }}
      </button>
      <mat-icon class="drawer-close-icon pointer" (click)="navigateToList()">close</mat-icon>
    </div>
  </div>

  <div class="drawer-details px-4 pb-4" id="clientsListFilter">
    <div class="inner-client">
      <div class="inner-demographics">
        <div *ngIf="ProviderFilterEnabled">
          <div>
            <label class="form-label">{{ 'Company' | i18next }}</label>
            <ng-container>
              <mat-form-field
                class="full-width mb-3 mr-2"
                floatLabel="never"
                [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': basicFormValues?.loading }"
              >
                <mat-select
                  multiple
                  (closed)="searchProvider? clearProviderSearch() : ''"
                  placeholder="Select Company"
                  name="provider"
                  #providerRef="ngModel"
                  [(ngModel)]="clientsListFilter.provider"
                  (openedChange)="setProviderOpenedStatus($event)" (selectionChange)="onProviderSelected($event)" (closed)="onProviderClosed()"
                  selectInfiniteScroll (scrolled)="onProviderScroll()" [threshold]="'45%'"
                >
                  <mat-form-field class="full-width mb-1" floatLabel="never">
                    <span matPrefix class="w-345px"> <mat-icon class="mat-20 mr-2">search</mat-icon> </span>
                    <input
                      matInput
                      ngModel
                      name="searchProvider"
                      [(ngModel)]="searchProvider"
                      [placeholder]="'Search Company' | i18next"
                      (keydown)="$event.stopPropagation()"
                    />
                    <div matSuffix *ngIf="searchProvider?.length" (click)="clearProviderSearch()">
                      <mat-icon class="drawer-close-icon pointer">close</mat-icon>
                    </div>
                  </mat-form-field>
                  <ng-container *ngIf="filteredProvider?.length; else noSearchResults">
                    <mat-option *ngFor="let provider of filteredProvider" [value]="provider?._id">
                      <div class="d-flex align-items-center">
                        <P class="f-14 text-truncate">{{ provider?.firmName }}</P>
                      </div>
                    </mat-option>
                  </ng-container>
                  <ng-template #noSearchResults>
                    <div class="d-flex align-items-center justify-content-center p-3 text-center background-grey">
                      <mat-icon class="mat-20 mr-2">search</mat-icon>
                      <span class="user-select-none">{{ 'No search results found' | i18next }}</span>
                    </div>
                  </ng-template>
                </mat-select>
                <mat-error *ngIf="providerRef.hasError('required')">{{ 'Please select provider' | i18next }}</mat-error>
              </mat-form-field>
            </ng-container>
          </div>
        </div>

        <div *ngIf="clientFilterEnabled">
          <div>
            <label class="form-label">{{ 'Company' | i18next }}</label>
            <ng-container>
              <mat-form-field
                class="full-width mb-3 mr-2"
                floatLabel="never"
                [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': basicFormValues?.loading }"
              >
                <mat-select
                  multiple
                  (closed)="searchCompany? clearCompanySearch() : ''"
                  placeholder="Select Company"
                  name="company"
                  #companyRef="ngModel"
                  [(ngModel)]="clientsListFilter.company"
                  (openedChange)="setCompanyOpenedStatus($event)" (selectionChange)="onCompanySelected($event)" (closed)="onCompanyClosed()"
                  selectInfiniteScroll (scrolled)="onCompanyScroll()" [threshold]="'45%'"
                >
                  <mat-form-field class="full-width mb-1" floatLabel="never">
                    <span matPrefix class="w-345px"> <mat-icon class="mat-20 mr-2">search</mat-icon> </span>
                    <input
                      matInput
                      ngModel
                      name="searchCompany"
                      [(ngModel)]="searchCompany"
                      [placeholder]="'Search Comany' | i18next"
                      (keydown)="$event.stopPropagation()"
                    />
                    <div matSuffix *ngIf="searchCompany?.length" (click)="clearCompanySearch()">
                      <mat-icon class="drawer-close-icon pointer">close</mat-icon>
                    </div>
                  </mat-form-field>
                  <ng-container *ngIf="filteredCompany?.length; else noSearchResults">
                    <mat-option *ngFor="let company of filteredCompany" [value]="company?._id">
                      <div class="d-flex align-items-center">
                        <P class="f-14 text-truncate">{{ company?.companyName }}</P>
                      </div>
                    </mat-option>
                  </ng-container>
                  <ng-template #noSearchResults>
                    <div class="d-flex align-items-center justify-content-center p-3 text-center background-grey">
                      <mat-icon class="mat-20 mr-2">search</mat-icon>
                      <span class="user-select-none">{{ 'No search results found' | i18next }}</span>
                    </div>
                  </ng-template>
                </mat-select>
                <mat-error *ngIf="companyRef.hasError('required')">{{ 'Please select company' | i18next }}</mat-error>
              </mat-form-field>
            </ng-container>
          </div>

          <div>
            <label class="form-label">{{ 'Contact Person' | i18next }}</label>
            <ng-container>
              <mat-form-field
                class="full-width mb-3 mr-2"
                floatLabel="never"
                [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': basicFormValues?.loading }"
              >
                <mat-select
                  multiple
                  (closed)="searchClient? clearClientSearch() : ''"
                  placeholder="Select Contact Person"
                  name="client"
                  #clientRef="ngModel"
                  [(ngModel)]="clientsListFilter.client"
                  (openedChange)="setOpenedStatus($event)" (selectionChange)="onUserSelected($event)" (closed)="onClosed()"
                  selectInfiniteScroll (scrolled)="onScroll()" [threshold]="'45%'"
                >
                  <mat-form-field class="full-width mb-1" floatLabel="never">
                    <span matPrefix class="w-345px"> <mat-icon class="mat-20 mr-2">search</mat-icon> </span>
                    <input
                      matInput
                      ngModel
                      name="searchClient"
                      [(ngModel)]="searchClient"
                      [placeholder]="'Search Contact Person' | i18next"
                      (keydown)="$event.stopPropagation()"
                    />
                    <div matSuffix *ngIf="searchClient?.length" (click)="clearClientSearch()">
                      <mat-icon class="drawer-close-icon pointer">close</mat-icon>
                    </div>
                  </mat-form-field>
                  <ng-container *ngIf="filteredClient?.length; else noSearchResults">
                    <mat-option *ngFor="let client of filteredClient" [value]="client?._id">
                      <div class="d-flex align-items-center">
                        <P class="f-14 text-truncate">{{ client.clientName }}</P>
                      </div>
                    </mat-option>
                  </ng-container>
                  <ng-template #noSearchResults>
                    <div class="d-flex align-items-center justify-content-center p-3 text-center background-grey">
                      <mat-icon class="mat-20 mr-2">search</mat-icon>
                      <span class="user-select-none">{{ 'No search results found' | i18next }}</span>
                    </div>
                  </ng-template>
                </mat-select>
                <mat-error *ngIf="clientRef.hasError('required')">{{ 'Please select contact person' | i18next }}</mat-error>
              </mat-form-field>
            </ng-container>
          </div>

        </div>

        <ng-template #multiLevel>
          <div>
            <label class="form-label">Industries</label>
            <app-con-filter-select
              [loading]="basicFormValues?.loading"
              [type]="1"
              [items]="demographyList.industryList"
              [markedItems]="clientsListFilter.industry"
              [isSelectMandatory]="false"
              matSelectPlaceHolder="Select Industries"
              matInputPlaceHolder="Search Industries"
              (selectComplete)="selectComplete($event)"
            >
            </app-con-filter-select>
          </div>
          <ng-container *ngIf="clientsListFilter?.industry?.length > 0 && industryChildList?.length > 0">
            <div>
              <label class="form-label">{{ 'Sub Industries' | i18next }}</label>
              <app-con-filter-select
                [loading]="basicFormValues?.loading"
                [type]="1"
                [items]="industryChildList"
                [markedItems]="clientsListFilter.industry"
                [isSelectMandatory]="false"
                matSelectPlaceHolder="{{ 'Select Sub Industries' | i18next }}"
                matInputPlaceHolder="{{ 'Search Sub Industries' | i18next }}"
                (selectComplete)="selectComplete($event)"
              >
              </app-con-filter-select>
            </div>
          </ng-container>

          <div>
            <label class="form-label">Capabilities</label>
            <app-con-filter-select
              [loading]="basicFormValues?.loading"
              [type]="4"
              [items]="demographyList.capabilityList"
              [markedItems]="clientsListFilter.capability"
              [isSelectMandatory]="false"
              matSelectPlaceHolder="Select Capabilities"
              matInputPlaceHolder="Search Capabilities"
              (selectComplete)="selectComplete($event)"
            >
            </app-con-filter-select>
          </div>

          <ng-container *ngIf="clientsListFilter?.capability?.length > 0 && capabilityChildList?.length > 0">
            <div>
              <label class="form-label">{{ 'Sub Capabilities' | i18next }}</label>
              <app-con-filter-select
                [loading]="basicFormValues?.loading"
                [type]="4"
                [items]="capabilityChildList"
                [markedItems]="clientsListFilter.capability"
                [isSelectMandatory]="false"
                matSelectPlaceHolder="{{ 'Select Sub Capabilities' | i18next }}"
                matInputPlaceHolder="{{ 'Search Sub Capabilities' | i18next }}"
                (selectComplete)="selectComplete($event)"
              >
              </app-con-filter-select>
            </div>
          </ng-container>
        </ng-template>

        <ng-container *ngIf="industryCapabilitySingleLevel; else multiLevel">
          <div>
            <label class="form-label">Industries</label>
            <app-con-filter-select
              [loading]="basicFormValues?.loading"
              [type]="1"
              [items]="demographyList.industryList"
              [markedItems]="clientsListFilter.industry"
              [isSelectMandatory]="false"
              matSelectPlaceHolder="Select Industries"
              matInputPlaceHolder="Search Industries"
              (selectComplete)="selectComplete($event)"
            >
            </app-con-filter-select>
          </div>

          <div>
            <label class="form-label">Capabilities</label>
            <app-con-filter-select
              [loading]="basicFormValues?.loading"
              [type]="4"
              [items]="demographyList.capabilityList"
              [markedItems]="clientsListFilter.capability"
              [isSelectMandatory]="false"
              matSelectPlaceHolder="Select Capabilities"
              matInputPlaceHolder="Search Capabilities"
              (selectComplete)="selectComplete($event)"
            >
            </app-con-filter-select>
          </div>
        </ng-container>

        <div>
          <label class="form-label">Regions</label>
          <app-con-filter-select
            [loading]="basicFormValues?.loading"
            [type]="2"
            [items]="demographyList.regionList"
            [markedItems]="clientsListFilter.region"
            [isSelectMandatory]="false"
            matSelectPlaceHolder="Select Regions"
            matInputPlaceHolder="Search Regions"
            (selectComplete)="selectComplete($event)"
          >
          </app-con-filter-select>
        </div>

        <div>
          <label class="form-label">Countries</label>
          <app-country-select
            [loading]="basicFormValues?.loading"
            [type]="3"
            [items]="filteredCountryList"
            [markedItems]="clientsListFilter.country"
            [isSelectMandatory]="false"
            matSelectPlaceHolder="Select Countries"
            matInputPlaceHolder="Search Countries"
            (selectComplete)="selectComplete($event)"
          >
          </app-country-select>
        </div>

        <div>
          <label class="form-label">{{ 'City' | i18next }}</label>
          <ng-container>
            <mat-form-field
              class="full-width mb-3 mr-2"
              floatLabel="never"
              [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': basicFormValues?.loading }"
            >
              <mat-select
                multiple
                (closed)="searchCity? clearSearch() : ''"
                placeholder="Select Cities"
                name="providerLeader"
                #userRef="ngModel"
                [(ngModel)]="clientsListFilter.city"
                (openedChange)="selectComplete($event)"
                selectInfiniteScroll
                (scrolled)="onProviderScrolled()"
                [threshold]="'45%'"
              >
                <mat-form-field class="full-width mb-1" floatLabel="never">
                  <span matPrefix class="w-345px"> <mat-icon class="mat-20 mr-2">search</mat-icon> </span>
                  <input
                    matInput
                    [(ngModel)]="searchCity"
                    name="cityName"
                    [placeholder]="'Search city' | i18next"
                    (click)="$event.stopPropagation()"
                    (keydown)="$event.stopPropagation()"
                  />
                  <div matSuffix *ngIf="searchCity.length" (click)="clearSearch()">
                    <mat-icon class="drawer-close-icon pointer">close</mat-icon>
                  </div>
                </mat-form-field>
                <ng-container *ngIf="filteredCity?.length; else noSearchResults">
                  <mat-option *ngFor="let city of filteredCity" [value]="city.name">
                    <div class="d-flex align-items-center">
                      <P class="f-14 text-truncate">{{ city.name }}</P>
                    </div>
                  </mat-option>
                </ng-container>
                <ng-template #noSearchResults>
                  <div class="d-flex align-items-center justify-content-center p-3 text-center background-grey">
                    <mat-icon class="mat-20 mr-2">search</mat-icon>
                    <span class="user-select-none">{{ 'No search results found' | i18next }}</span>
                  </div>
                </ng-template>
              </mat-select>
              <mat-error *ngIf="userRef.hasError('required')">{{ 'Please select cities' | i18next }}</mat-error>
            </mat-form-field>
          </ng-container>
          <ng-template #selectFirmPartner>
            <mat-form-field class="full-width" [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': basicFormValues?.loading }" floatLabel="never">
              <mat-select
                [(ngModel)]="contracts.providerLeader"
                name="providerLeader"
                placeholder="{{ 'Select Provider Leader' | i18next }}"
                #providerLeader="ngModel"
                [required]="true"
                [disabled]="isReadOnly"
              >
                <mat-option *ngFor="let partner of providerPartnerList" [value]="partner._id">{{ partner?.name }}</mat-option>
              </mat-select>
              <mat-error *ngIf="procurementLeader.hasError('required')">{{ 'Provider leader is required' | i18next }}</mat-error>
            </mat-form-field>
          </ng-template>

          <ng-container  *ngIf="clientsListFilter.city?.length">
            <div class="d-flex flex-wrap account-content mb-2">
              <div class="tag-clouds p-1" [ngClass]="{'border-line' : clientsListFilter?.city?.length > 3}">
                <mat-chip-list>
                  <ng-container *ngFor="let item of filteredCity; let i = index">
                    <ng-container *ngIf="clientsListFilter?.city?.includes(item._id)">
                      <mat-chip color="warn-color" class="project-chip">
                        <span class="chip-text">{{ item['name'] }}</span>
                        <mat-icon [hidden]="loading" matChipRemove (click)="removeSelected(item['_id'])">
                          close
                        </mat-icon>
                      </mat-chip>
                    </ng-container>
                  </ng-container>
                </mat-chip-list>
              </div>
            </div>
          </ng-container>
        </div>

        <div *ngIf="ProviderFilterEnabled">
          <div>
            <label class="form-label">CGN Status</label>
            <mat-form-field class="full-width mb-3 mr-2" floatLabel="never" [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': basicFormValues?.loading }">
              <mat-select [(ngModel)]="clientsListFilter.cgnStatus" placeholder="{{ 'Select CGN Status' | i18next }}" name="cgnStatus" multiple>
                <mat-option *ngFor="let status of cgnStatus" [value]="status">{{ status | titlecase}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="clientFilterEnabled">
          <div>
            <label class="form-label">Stripe Status</label>
            <mat-form-field class="full-width mb-3 mr-2" floatLabel="never" [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': basicFormValues?.loading }">
              <mat-select [(ngModel)]="clientsListFilter.stripeStatus" placeholder="{{ 'Select Stripe Status' | i18next }}" name="stripeStatus" multiple>
                <mat-option *ngFor="let status of stripeStatus" [value]="status.value">{{ status.label | titlecase}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div> 
          <div>
            <label class="form-label">MSP Status</label>
            <mat-form-field class="full-width mb-3 mr-2" floatLabel="never" [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': basicFormValues?.loading }">
              <mat-select [(ngModel)]="clientsListFilter.mspStatus" placeholder="{{ 'Select MSP Status' | i18next }}" name="mspStatus" multiple>
                <mat-option *ngFor="let status of mspStatus" [value]="status">{{ status | titlecase}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div> 
        </div>

        <div *ngIf="clientFilterEnabled || ProviderFilterEnabled">
          <div>
            <label class="form-label">Consource Status</label>
              <mat-form-field class="full-width mb-3 mr-2" floatLabel="never" [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': basicFormValues?.loading }">
                <mat-select [(ngModel)]="clientsListFilter.consourceStatus" placeholder="{{ 'Select Consource Status' | i18next }}" name="consourceStatus" multiple>
                  <mat-option *ngFor="let status of consourceStatus" [value]="status">{{ status | titlecase}}</mat-option>
                </mat-select>
              </mat-form-field>
          </div>
      
          <div>
            <label class="form-label">Onboarding Status</label>
            <mat-form-field class="full-width mb-3 mr-2" floatLabel="never" [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': basicFormValues?.loading }">
              <mat-select [(ngModel)]="clientsListFilter.onBoardingStatus" placeholder="{{ 'Select On Boarding Status' | i18next }}" name="onBoardingStatus" multiple>
                <mat-option *ngFor="let status of onBoardingStatus" [value]="status.value">{{ status.label | titlecase }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        
          <div>
            <label class="form-label">Marketplace Status</label>
            <mat-form-field class="full-width mb-3 mr-2" floatLabel="never" [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': basicFormValues?.loading }">
              <mat-select [(ngModel)]="clientsListFilter.marketplaceStatus" placeholder="{{ 'Select MarketPlace Status' | i18next }}" name="marketplaceStatus" multiple>
                <mat-option *ngFor="let status of marketplaceStatus" [value]="status">{{ status | titlecase}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        
        <div *ngIf="companySizeFilterEnabled">
          <div>
            <label class="form-label">Company Size</label>
            <mat-form-field class="full-width mb-2" floatLabel="never" [ngClass]="{ 'skeleton-loader skeleton-title w-100 h-auto': basicFormValues?.loading }">
              <mat-select placeholder="Select Company Size" [(ngModel)]="clientsListFilter.companySize" name="companySizeFilter" color="primary">
                <mat-option class="customize-options" *ngFor="let item of MarketExplorationCompanySizeFilterValues" [value]="item">{{ item }} </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="improveoDiversityFilterEnabled">
          <div>
            <label class="form-label">{{ 'Diversity' | i18next }}</label>
            <app-con-filter-select
              [loading]="basicFormValues?.loading"
              [type]="5"
              [items]="ImproveoCompanyDiversity"
              [markedItems]="clientsListFilter.improveoDiversity"
              [isSelectMandatory]="false"
              matSelectPlaceHolder="{{ 'Select Diversity' | i18next }}"
              matInputPlaceHolder="{{ 'Search Diversity' | i18next }}"
              (selectComplete)="selectComplete($event)"
            >
            </app-con-filter-select>
          </div>
        </div>
        <div *ngIf="improveoCommunityImpactFilterEnabled">
          <div>
            <label class="form-label">{{ 'Community Impact' | i18next }}</label>
            <app-con-filter-select
              [loading]="basicFormValues?.loading"
              [type]="6"
              [items]="ImproveoCompanyCommunityImpactList"
              [markedItems]="clientsListFilter.improveoCommunityImpact"
              [isSelectMandatory]="false"
              matSelectPlaceHolder="{{ 'Select Community Impact' | i18next }}"
              matInputPlaceHolder="{{ 'Search Community Impact' | i18next }}"
              (selectComplete)="selectComplete($event)"
            >
            </app-con-filter-select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="drawer-footer d-flex align-items-center justify-content-start p-4">
    <button class="mr-12" color="accent" mat-stroked-button (click)="navigateToList()" type="button" back-button>Cancel</button>
    <button color="primary" type="button" mat-flat-button [disabled]="disableFilter()" [hidden]="basicFormValues?.loading" (click)="onFormSubmit()">
      Apply
    </button>
    <button color="primary" type="button" mat-flat-button [disabled]="basicFormValues.loading" [hidden]="!basicFormValues?.loading">Loading...</button>
  </div>
</form>
