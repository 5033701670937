<div class="settings-container d-flex">
  <div class="settings-list p-3 d-flex flex-column">
    <a routerLink="{{ 'account' }}" [class.active]="currentUrl.includes('account')">{{ 'Account' | i18next }}</a>
     <!-- TODO: can be removed once the release/v1.65 Completed -->
    <!-- <a routerLink="{{ 'contracts' }}" [class.active]="currentUrl.includes('contracts')">{{ 'TranslateContracts' | i18next }}</a> -->
    <ng-container *ngIf="!isFirm">
      <a routerLink="{{ 'modules' }}" [class.active]="currentUrl.includes('modules')">{{ 'Modules' | i18next }}</a>
    </ng-container>
    <ng-container *ngIf="!isFirm">
      <a routerLink="{{ 'billing-info' }}" [class.active]="currentUrl.includes('billing-info')">{{ 'Billing Info' | i18next }}</a>
    </ng-container>
    <ng-container *ngIf="!isFirm">
      <ng-container>
        <a routerLink="{{ 'invoice-billing' }}" [class.active]="currentUrl.includes('invoice-billing')">{{ 'Invoices' | i18next }}</a>
      </ng-container>
       <!-- TODO: can be removed once the release/v1.65 Completed -->
      <!-- <ng-container>
        <a routerLink="{{ 'off-boarding' }}" [class.active]="currentUrl.includes('off-boarding')">{{ 'Off-boarding' | i18next }}</a>
      </ng-container> -->
    </ng-container>
  </div>

  <div class="flex-one ml-3">
    <router-outlet></router-outlet>
  </div>
</div>
