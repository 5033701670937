import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// tslint:disable-next-line:nx-enforce-module-boundaries
import { ConpulseMaterialModule } from 'libs/conpulse/src/index';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { DateRangeFilterComponent } from './date-range-filter.component';
import { DateFilterActionModule } from '../../dialogs/date-filter-action/date-filter-action.module';


@NgModule({
  imports: [
    RouterModule,
    MatMenuModule,
    MatIconModule,
    CommonModule,
    DateFilterActionModule,
    ConpulseMaterialModule
  ],
  declarations: [
    DateRangeFilterComponent
  ],
  exports: [
    DateRangeFilterComponent
  ]
})
export class DateRangeFilterModule {
}
