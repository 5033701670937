import { Inject, Injectable, NgZone } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, of, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class CustomAuthHttpInterceptor implements HttpInterceptor {
  protected environment: any;

  constructor(private auth: AuthService, @Inject('AppEnvironment') environmentConfig, @Inject(DOCUMENT) private document: any) {
    this.environment = environmentConfig;
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const s3Pattern = /^https:\/\/.*.s3./;
    const urlPatten = /user\/.*\/sendResetEmail/
    if (req.url.startsWith(this.environment.APIEndpoint + 'embeddableProjectScoreCard') ||               // For Embeddable Project Scorecard
      req.url.startsWith(this.environment.APIEndpoint + 'survey/ey') ||                                // For Attending Surveys
      req.url.startsWith(this.environment.APIEndpoint + 'sourcing/ey') ||                                // For Sourcing acceptance
      req.url.startsWith(this.environment.APIEndpoint + 'passwd-reset/ey') ||    
      urlPatten.test(req.url)   ||                         // For auth0 reset password
      s3Pattern.test(req.url)
    ) {
      return next.handle(req)
    }
    return this.auth.getAccessTokenSilently().pipe(
      mergeMap(token => {
        const tokenReq = req.clone({
          setHeaders: { Authorization: `Bearer ${token}` }
        });
        return next.handle(tokenReq);
      }),
      catchError((err: HttpErrorResponse) => {
        if (err?.error === 'login_required') {
          return throwError(() => err);
        }
        return throwError(err);
      })
    );
  }
}
