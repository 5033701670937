<div class="p-12 bg-white border-radius-6 mb-12">
  <h3 class="page-title font-Jakarta">{{ 'Invoices' }}</h3>
</div>

<div class="client-billing-container p-20 bg-white border-radius-6">
  <div class="d-flex align-items-center justify-content-end mb-3">
    <div class="w-345px">
      <mat-form-field class="full-width mb-0" floatLabel="never">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput placeholder="{{ 'Search' | i18next }}" [(ngModel)]="invoiceBillingList.search"
          (keyup.enter)="getInvoiceBilling()" />
        <mat-icon [hidden]="invoiceBillingList?.search?.length == 0" class="txt-shuttle-grey pointer" matSuffix
          mat-icon-button (click)="clearSearch()">close</mat-icon>
      </mat-form-field>
    </div>

    <button *ngIf="!invoiceBillingList?.isLoading" matBadgeColor="warn" class="ml-12 icon-button" mat-stroked-button
      color="accent" [matBadge]="filterCount" [matBadgeHidden]="!filterCount" (click)="openFilter()">
      <mat-icon class="mat-20 mr-2"> filter_list</mat-icon>Filter
    </button>

    <button [disabled]="!isClientActive" *ngIf="invoiceBillingList.isAdmin" mat-flat-button color="secondary"
      class="ml-3" (click)="toggleAddBillingDrawer('add', '')">
      {{ 'Add Invoice' | i18next }}
    </button>
    <button *ngIf="!invoiceBillingList.isAdmin" mat-flat-button color="secondary" class="ml-3"
      (click)="openCustomerSessionPortal()">
      {{ 'Open Stripe Portal' | i18next }}
    </button>
  </div>

  <conpulse-web-billing-table [tableValues]="invoiceBillingList" (changed)="onChange()" [isPagination]="true"
    (edit)="toggleAddBillingDrawer('edit', $event)" [currencySymbol]="currencySymbol" [minorUnits]="minorUnits"
    [errorMessage]="'No Invoice & Billing records here'"
    [isSuperAdmin]="invoiceBillingList.isAdmin"></conpulse-web-billing-table>
</div>