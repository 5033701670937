import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClientAccountComponent } from './client-account.component';


const routes: Routes = [
  {
    path: '',
    component: ClientAccountComponent,
    children: [
      {
        path: '',
        redirectTo: 'platform',
        pathMatch: 'full',
      },
      {
        path: 'platform',
        loadChildren: () => import('./platform-account/platform-account.module').then((m) => m.PlatformAccountModule),
      },
      {
        path: 'marketplace',
        loadChildren: () => import('./marketplace-account/marketplace-account.module').then((m) => m.MarketplaceAccountModule),
      },
      {
        path: 'managed-services',
        loadChildren: () => import('./managed-services/managed-services.module').then((m) => m.ManagedServicesModule),
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientAccountRoutingModule { }
